<template>
  <div class="input-fr-box">
    <img class="succ-icon" src="../assets/images/register/succ-icon.png" alt="">
    <p class="p1">{{$t('loginRegister.loginSuccessful')}}</p>
    <p class="p2">{{$t('loginRegister.loginSuccTitle')}}</p>
    <div class="link-box">
      <a class="link-btn1" href="./deposit">{{$t('loginRegister.loginSuccBtn1')}}</a>
      <button @click="perfectData()" class="link-btn2">{{$t('loginRegister.loginSuccBtn2')}}</button>
    </div>  
  </div>
</template>

<script>
import {useRouter} from 'vue-router'
export default{
  data() {
    return {
    }
  },
  created() {
   
  },
  methods: {
    perfectData(){
      const that = this;
      that.$router.push({
        path: 'perfectData',
        query: {         
          dataTypeUrl:true
        }
        
      });
    }
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .input-fr-box .succ-icon{width: 115px;height: 103px;margin: auto;margin-bottom: 32px;display: block;}
  .input-fr-box .p1{font-size: 26px;margin-bottom: 22px;color: #000;text-align: center;}
  .input-fr-box .p2{font-size: 18px;color: #4c4c4c;text-align: center;}
  .input-fr-box .link-box{margin-top: 12px;display: flex;flex-direction: column;justify-content: space-between;align-items: center;}
  .input-fr-box .link-btn1{width: 385px;height: 55px;text-align: center; font-size: 18px;margin-top:20px;color: #000;border: 1px solid #ffd94c;background: #ffd94c;border-radius: 5px;display: block;}
  .input-fr-box .link-btn1:hover{background: #ffe27b;}
  .input-fr-box .link-btn2{width: 385px;height: 55px;padding: 10px 30px;text-align: center; font-size: 18px;margin-top:20px;color: #000;border: 1px solid #ffd94c;background: #fffae6;border-radius: 5px;display: block;}
  .input-fr-box .link-btn2:hover{background: #ffe27b;}

  @media screen and (max-width: 768px) {
  .input-fr-box .succ-icon{width: 6rem;height: 5.3rem;margin: auto;margin-bottom: 1rem;display: block;}
  .input-fr-box .p1{font-size: 1.2rem;margin-bottom: 1rem;color: #000;text-align: center;}
  .input-fr-box .p2{font-size: 0.9rem;color: #4c4c4c;text-align: center;margin-bottom: 0.5rem;}
  .input-fr-box .link-box{margin-top: 0.6rem;display: flex;flex-direction: column;justify-content: space-between;align-items: center;}
  .input-fr-box .link-btn1{width:100%; text-align: center; font-size: 0.8rem;margin-top:0.8rem;color: #000;border: 1px solid #ffd94c;background: #ffd94c;border-radius: 5px;display: block;}
  .input-fr-box .link-btn1:hover{background: #ffe27b;}
  .input-fr-box .link-btn2{width:100%;padding: 0.6rem 1.6rem;text-align: center; font-size: 0.8rem;margin-top:0.8rem;color: #000;border: 1px solid #ffd94c;background: #ffeda7;border-radius: 5px;display: block;}
  .input-fr-box .link-btn2:hover{background: #ffe27b;}
  }
</style>
